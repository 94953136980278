import { NonPayrollCommitmentForm, PayrollForm } from '../types/types'

export const PROFORMA_TYPE_OPTIONS = [
  {
    name: 'Cost price per hour',
    value: 'CostPricePerHour',
  },
  {
    name: 'Net salary per month',
    value: 'NetSalaryPerMonth',
  },
  {
    name: 'Gross wage per hour',
    value: 'GrossWagePerHour',
  },
]

export const WORK_TIME_PER_WEEK_OPTIONS = [
  '40',
  '37',
  '36',
  '32',
  '24',
  '20',
  '16',
]

export const CANDIDATE_LESS_THAN_30_OPTIONS = [
  {
    name: 'N/A',
    value: 'NA',
  },
  {
    name: 'Yes',
    value: 'Yes',
  },
  {
    name: 'No',
    value: 'No',
  },
]

export const ALL_IN_RATE_UNIT_OPTIONS = [
  {
    name: 'per hour',
    value: 'Hour',
  },
  {
    name: 'per day',
    value: 'Day',
  },
]

export const YES_NO_OPTIONS = ['Yes', 'No']

export const INITIAL_FORM = {
  type: 'Payrolled',
  client: 'ABN AMRO',
  proformaType: 'CostPricePerHour',
  proformaBasis: '',
  requiresWorkPermit: 'No',
  eligible30percentRuling: 'No',
  workTimePerWeek: '40',
  candidateLessThan30: 'NA',
  candidateHasMasterDegree: 'No',
  workHomeDistance: '40',
} as PayrollForm

export const INITIAL_NON_PAYROLL_FORM = {
  client: 'ABN AMRO',
  type: 'SelfEmployed',
  allInRate: '',
  allInRateUnit: 'Hour',
  workTimePerWeek: '',
  requiresWorkPermit: 'No',
  eligible30percentRuling: 'No',
  statementOfWork: 'No',
  language: 'EN',
} as NonPayrollCommitmentForm
